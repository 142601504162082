/** @jsx jsx */
import { graphql } from "gatsby";
import React, { memo } from "react";
import { loadable } from "shared/helpers/loadableComponent";
import usePageContext from "shared/hooks/usePageContext";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

const ShowtimesGrid = loadable(() => import("gatsby-theme-showtimes-grid"), {
  resolveComponent: (components) => components.ShowtimesGrid,
});

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetShowtimes" }>;
}

const ShowtimesWidget: React.FC<Props> = ({
  isFirst,
  isNested,
  isLast,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  const shape = widget.showtimesShape;
  const { theaterId } = usePageContext();
  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <ShowtimesGrid
        forcedTheaterId={theaterId || undefined}
        periodFilter={shape?.periodFilter}
        groupType={shape?.groupType}
        groupBy={shape?.groupBy}
        display={shape?.display}
        includeDatePicker={shape?.options?.includes("DATEPICKER")}
        includeComingSoonTab={shape?.options?.includes("COMING_SOON_TAB")}
        includeDateSlider={shape?.periodFilter?.includes("INDIVIDUAL_DAYS")}
      />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetShowtimes on WidgetShowtimes {
    id
    __typename
    showtimesShape {
      periodFilter
      groupType
      groupBy
      options
      display
    }
  }
`;

export default memo(ShowtimesWidget);
